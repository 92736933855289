/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const CheckboxOnIconSvg = forwardRef((props, svgRef) => (
	<svg
		id="prefix__Livello_1"
		xmlns="http://www.w3.org/2000/svg"
		x={0}
		y={0}
		viewBox="0 0 24 24"
		xmlSpace="preserve"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<style>{'.prefix__st0{fill:#1d1d1b}'}</style>
		<path className="prefix__st0" d="M20.5 3.5v17.1h-17V3.5h17m1.3-1.3H2.2v19.6h19.6V2.2z" />
		<path className="prefix__st0" d="M9.7 17.2l-4.4-4.4.9-.9 3.5 3.5 8.1-8.1.9.9z" />
	</svg>
))
CheckboxOnIconSvg.displayName = 'CheckboxOnIconSvg'

const CheckboxOnIcon = forwardRef((props, ref) => <Icon component={CheckboxOnIconSvg} ref={ref} {...props} />)
CheckboxOnIcon.displayName = 'CheckboxOnIcon'

CheckboxOnIcon.defaultProps = {
	...Icon.defaultProps,
}
CheckboxOnIcon.propTypes = {
	...Icon.propTypes,
}

export default CheckboxOnIcon
export { CheckboxOnIconSvg }
