/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

/** this file is used by project to add new icons */
import dynamicHydrate from '@bluheadless/ui-logic/src/dynamic-hydration/dynamic-hydrate'
import EmptyIcon from '../icon/empty'

export * from './project-related-icons'
export const LogoSmallIcon = dynamicHydrate(() => import('@/components/ui/atoms/icons/logo-small-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const LogoSmallIconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/logo-small-icon').then((mod) => mod.LogoSmallIconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const OpenIcon = dynamicHydrate(() => import('@/components/ui/atoms/icons/open-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const OpenIconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/open-icon').then((mod) => mod.OpenIconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const PlayIconFilled = dynamicHydrate(() => import('@/components/ui/atoms/icons/play-icon-filled'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const PlayIconFilledSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/play-icon-filled').then((mod) => mod.PlayIconFilledSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Returns2Icon = dynamicHydrate(() => import('@/components/ui/atoms/icons/returns-2-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Returns2IconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/returns-2-icon').then((mod) => mod.Returns2IconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const ReturnsIcon = dynamicHydrate(() => import('@/components/ui/atoms/icons/returns-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const ReturnsIconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/returns-icon').then((mod) => mod.ReturnsIconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const ShippingIcon = dynamicHydrate(() => import('@/components/ui/atoms/icons/shipping-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const ShippingIconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/shipping-icon').then((mod) => mod.ShippingIconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const TiktokIcon = dynamicHydrate(() => import('@/components/ui/atoms/icons/tiktok-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const TiktokIconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/tiktok-icon').then((mod) => mod.TiktokIconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const TrackIcon = dynamicHydrate(() => import('@/components/ui/atoms/icons/track-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const TrackIconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/track-icon').then((mod) => mod.TrackIconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const WorldIcon = dynamicHydrate(() => import('@/components/ui/atoms/icons/world-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const WorldIconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/world-icon').then((mod) => mod.WorldIconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
